/*
 * @Author: 张超越 
 * @Date: 2023-08-07 10:46:09 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-03-21 15:48:57
 */

<template>
  <div class="BusinessCascaderChannels">
    <CommonCascader
      v-if="readyRender"
      :ref="`B_CommonCascader_${field}`"
      :items="cascaderData"
      :defaultActiveLeftKey="defaultActiveLeftKey"
      :defaultValue="defaultValue"
      :disableds="disableds"
      :cacheAllNodes="true"
      :emitTreeDatas="emitTreeDatas"
      :nameClickCheck="nameClickCheck"
      @change="handleCascaderChange"
      @ready="handleCascaderReady"
      >
    </CommonCascader>
  </div>
</template>

<script>
// Components
import CommonCascader from '@/components/v2/system/CommonCascader'

// Tools
import { v4 as uuidv4 } from 'uuid'

// Services
import clueServices from '@/services/clueServices'
import baseDataServices from '@/services/baseDataServices'

export default {
  name: 'BusinessCascaderChannels',
  components: {
    CommonCascader
  },
  props: {
    field: {
      type: String,
      default: 'businessCascaderChannels'
    },
    levels: {
      // 启用的层级，默认 5
      type: Number,
      default: 5
    },
    level1Merges: {
      // 第一层级合并项
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: Array,
      default: () => [] // [[1,2,3], [4,5,6], [7,8,9]]
    },
    emitTreeDatas: {
      type: Boolean,
      default: () => true
    },
    nameClickCheck: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      readyRender: false,
      cascaderData: [],
      disableds: [],
      defaultActiveLeftKey: '',
      cache: {
        checkedLength: 0
      },
      searchKey: '',
    }
  },
  watch:{
    defaultValue:{
      handler(val){
        this.defaultActiveLeftKey = val
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      // 检查levels
      if (this.levels < 0 || this.levels > 5) {
        throw new Error('levels must be 0, 1, 2, 3, 4, 5')
      }

      await this.getAndSetChannelsData()
    },
    async getAndSetChannelsData() {
      // 获取所有渠道
      const res = await baseDataServices.getAllChannelList().catch(err => console.error(err))
      console.log('getChannels', res)
      if (!res || !res.length) {
        console.info('geted api but not found channels')
        this.readyRender = true
        return false
      }

      const channelsTree = this.formatChannelsTree(res || [])

      console.info('geted channels, formated', channelsTree)
      this.defaultActiveLeftKey = this.getDefaultActiveLeftKeyFromDV(channelsTree)
      this.cascaderData = channelsTree
      this.readyRender = true
    },
    getDefaultActiveLeftKeyFromDV(channelsTree) {
      // 如果有defaultValue，则从里面拿第一个，没有则从接口拿
      const ids = this.defaultValue.flat()

      if (!ids || !ids.length) {
        return channelsTree[0]?.value
      }

      return ids[0]
    },
    formatChannelsTree(arr = [], level = 0) {
      level++

      const getChilds = (arr, mergeObj = {}, level) => {
        if (level >= this.levels) return []
        level++

        if (!arr || !arr.length) return []

        return arr.map(item => {
          const { id: value, } = item
          return {
            ...item,
            _uuid: uuidv4(),
            _hiddenChilds: true,
            value,
            level,
            children: getChilds(item.children || [], mergeObj, level),
            ...mergeObj
          }
        })
      }

      const tree = arr.map(item => {
        const { onClick, id: value, } = item

        const children = !item.disabledRequest ? getChilds(item.children, { onClick }, level) : []

        return {
          ...item,
          _hiddenChilds: true,
          onClick,
          level,
          value,
          children
        }
      })

      return tree
    },
    handleCascaderChange(value) {
      console.info('handleCascaderChange, BusinessCascaderChannels', value)
      this.cache.checkedLength = value.checkedValues.length || 0
      this.$emit('change', value)
    },
    handleCascaderReady() {
      this.$nextTick(() => {
        const refName = `B_CommonCascader_${this.field}`
        const refNode = this.$refs[refName]
        refNode && refNode.ON_DIV_SCROLL()
      })
    },

    // 对外暴露的方法
    CHECK_OFF_ITEM(tagValue) {
      console.log('CHECK_OFF_ITEM', tagValue)
      const refName = `B_CommonCascader_${this.field}`
      const refNode = this.$refs[refName]
      refNode.CHECK_OFF_ITEM(tagValue)
    },
    
    SEARCH(searchKey) {
      console.log('SEARCH', searchKey)
      this.searchKey = searchKey

      const handleItems = (items) => {
        for (let item of items) {
          if (item.children && item.children.length) {
            handleItems(item.children)
          }

          // 处理子级的父级，但凡有一个子级显示，父级就显示
          const childsSomeShow = item.children && item.children.length && item.children.some(item => !item._hiddenSelf)
          if (!searchKey || item.name.includes(searchKey) || childsSomeShow) {
            this.$set(item, '_hiddenSelf', false)

            // 展开子集
            this.$set(item, '_hiddenChilds', searchKey ? false : true)
          } else {
            this.$set(item, '_hiddenSelf', true)
          }

          // console.log('_hiddenSelf', item._hiddenSelf, '_hiddenChilds', item._hiddenChilds)
        }
      }

      handleItems(this.cascaderData)

      const refName = `B_CommonCascader_${this.field}`
      const refNode = this.$refs[refName]
      refNode.RECOMPUTE_CHECK_HALF()
      refNode.SET_ACTIVE_LEFT_KEY()
    }
  },
}
</script>