import Vue from 'vue'
import PositionPicker from './picker'
import router from '@/router'
import utils from '@/utils/area'

const PositionPickerConstructor = Vue.extend(PositionPicker)

let ins = null
// locationList : 用来做过滤用 {provinceCode : '', cityCode: ''}
const selectLocation = (locationList, { type = 'position', code = '', columnsNum } = {}, { provinceCode = '', cityCode = '' } = {}) => {
  if (!ins) {
    ins = new PositionPickerConstructor({
      el: window.document.createElement('div')
    })
    window.document.body.appendChild(ins.$el)
  }
  router.afterEach(to => {
    if (ins){
      ins.reject()
      ins.visible=false
    }
  })
  if (cityCode) {
    delete utils.city_list[cityCode]
  }
  if (provinceCode && ['110000','120000','310000','500000'].includes(provinceCode)) {
    delete utils.province_list[provinceCode]
  }
  return new Promise((resolve, reject) => {
    if (code) ins.code = code
    if (columnsNum) ins.columnsNum = columnsNum
    ins.type = type
    ins.visible = true
    ins.resolve = resolve
    ins.reject = reject
    ins.areaList = utils
  })
}

export default selectLocation
