/*
 * @Author: 张超越 
 * @Date: 2023-08-03 10:56:24 
 * @Last Modified by: 张超越
 * @Last Modified time: 2024-02-22 17:50:05
 */

<template>
  <div class="CommonSelectTagsInput">
    <!-- <div v-show="!getTags.length" class="placeholder">{{ getPlaceholder }}</div> -->
    <div class="tags">
      <div v-for="(item,index) of getTags" :key="index" class="tag" @click.stop="handleClick(item, index)">
        <span class="name">{{ item }}</span>
        <van-icon name="cross" class="icon" />
      </div>

      <div v-show="getShowMoreTag" class="tag more">+{{ tags.length - tagsNumber }}</div>

      <!-- 模糊搜索框 -->
      <img src="@/images/search.png" class="search" alt="">
      <input v-model="searchKey" :class="['input', !getTags || !getTags.length ? 'with-full' : '']" :placeholder="getPlaceholder" @change="handleInputChange" />
    </div>
    <div class="right" @click.stop="handleRightClick">
      <!-- <van-icon name="arrow-up" /> -->
      <!-- <van-icon name="arrow-down" /> -->
    </div>
  </div>
</template>

<script>
// Tools
import { last } from 'lodash'
import { i18n } from '@/main'

export default {
  name: 'CommonSelectTagsInput',
  props: {
    tagsNumber: {
      type: Number,
      default: 1
    },
    tags: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchKey: ''
    }
  },
  computed: {
    getTags() {
      let arr = this.tags.slice(0, this.tagsNumber)
      return arr.map(arr => last(arr).name)
    },
    getShowMoreTag() {
      return this.tags.length > this.tagsNumber
    },
    getPlaceholder() {
      const lang = i18n.locale
      return this.placeholder || lang.includes('zh') ? '按下回车键以搜索' : 'Press enter to search'
    }
  },
  methods: {
    handleClick(item, index) {
      const tagValue = this.tags[index]
      if (!tagValue) return console.error('tagValue is undefined')
      this.$emit('delete', tagValue.map(item => item.value))
    },
    handleRightClick() {
      this.$emit('clickRight')
    },
    handleInputChange() {
      this.$emit('search', this.searchKey)
    }
  }
}
</script>

<style lang="less" scoped>
.CommonSelectTagsInput {
  height: 34px;
  border-radius: 4px;
  border: 1px solid #dee0e3;
  box-sizing: border-box;
  background: #ffffff;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .placeholder {
    flex: 1;
    padding: 0 5px;
    color: #7d7d7d;
    font-size: 12px;
  }

  .tags {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-x: auto;

    .search {
      height: 14px;
      margin-left: 4px;
    }
    .tag {
      border-radius: 2px;
      border: 1px solid #e7e8ea;
      box-sizing: border-box;
      background: #f2f3f5;
      text-align: center;
      color: #323232;
      font-family: PingFang SC;
      font-size: 12px;
      margin-right: 4px;
      padding: 2px 8px;
      display: flex;
      align-items: center;

      &.more {
        color: #7D7D7D;
      }
      .name {
        // 超出字符显示为省略号
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;  
      }
      .icon {
        margin-left: 4px;
        color: #1c1f2399;
        font-size: 15px;
      }
    }
  }

  .input {
    // border: 1px solid #e7e8ea;
    border: none;
    font-size: 12px;
    margin-right: 4px;
    padding: 2px 8px;
    display: flex;
    border-radius: 2px;
    box-sizing: border-box;
    width: 120px;

    &.with-full {
      flex: 1;
    }
  }

  .right {
    padding-right: 4px;
    cursor: pointer;
  }
}
</style>