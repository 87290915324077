import { render, staticRenderFns } from "./picker.vue?vue&type=template&id=1a3979ec&scoped=true"
import script from "./picker.vue?vue&type=script&lang=js"
export * from "./picker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3979ec",
  null
  
)

export default component.exports