<template>
  <van-popup v-model="visible" position="bottom" :style="{ width: '100%' }">
    <van-area
      :value="code"
      title="选择省市"
      :area-list="areaList"
      :columns-num="columnsNum"
      :columns-placeholder="['请选择', '请选择', '请选择']"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      areaList: {},
      visible: false,
      code:'',
      columnsNum: 3
    }
  },
  methods: {
    onConfirm(value) {
      this.resolve && this.resolve(value)
      this.visible = false
    },
    onCancel() {
      this.reject && this.reject()
      this.visible = false
    }
  }
}
</script>

<style scoped></style>
